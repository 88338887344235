import FREE_SEATS from '@/components/shared/gql/FreeSeats.query.gql';
import { apolloClient } from '@/plugins/vue-apollo';
import { ListenerDeviceType, MediasoupClient } from '@linkbox/rtc-sdk';
import * as _ from 'lodash';
import store from '../store';

export default class ConnectionClient {
  private client: MediasoupClient = new MediasoupClient();

  async connect(mediaRoomUrl) {
    store.dispatch('mediaSoupConnection/startConnecting');
    await this.client.connect({ mediaRoomUrl });
    this.client.on('consumerClosed', (consumerId) =>
      store.dispatch('mediaSoupConnection/audioConsumer/remove', consumerId)
    );
    this.client.on('close', () => {
      store.dispatch('mediaSoupConnection/audioConsumer/clear');
      store.dispatch('selectChannel', null);
    });
    this.client.on('newConsumer', (consumer) => store.dispatch('mediaSoupConnection/audioConsumer/add', consumer));
    this.client.on('micStreamSet', (stream) => store.dispatch('mediaSoupConnection/audioProducer/setStream', stream));
    this.client.on('micProducerSet', (producer) =>
      store.dispatch('mediaSoupConnection/audioProducer/setProducer', producer)
    );

    store.dispatch('mediaSoupConnection/setConnected', true);
  }

  async connectAndProduce(mediaRoomUrl, deviceId) {
    await this.connect(mediaRoomUrl);
    await this.client?.initializeSendTransport();
    const { peers } = await this.client.join({ deviceType: ListenerDeviceType.Browser });
    await this.client?.enableMic({ inputDeviceId: deviceId });

    for (const peer of peers) {
      store.dispatch('mediaSoupConnection/addPeer', { ...peer, consumers: [], dataConsumers: [] });
    }
  }

  async connectAndConsume(mediaRoomUrl, channelId) {
    await this.connect(mediaRoomUrl);
    await this.client?.initializeReceiveTransport();
    const { peers } = await this.client.join({ channelId, deviceType: ListenerDeviceType.Browser });

    for (const peer of peers) {
      store.dispatch('mediaSoupConnection/addPeer', { ...peer, consumers: [], dataConsumers: [] });
    }
  }

  async disconnect() {
    if (this.client) {
      this.client.disconnect();
      this.client.removeAllListeners();
    }
    store.dispatch('mediaSoupConnection/audioConsumer/clear');
    store.dispatch('mediaSoupConnection/audioProducer/clear');
    store.dispatch('mediaSoupConnection/setDisconnected');
  }

  async canConnect() {
    const freeSeats = await apolloClient
      .query({
        query: FREE_SEATS,
        fetchPolicy: 'no-cache',
      })
      .then((response) => _.get(response, 'data.freeSeats', 0));
    return freeSeats > 0;
  }

  async muteMic() {
    try {
      await this.client.muteMicrophone();
      store.dispatch('mediaSoupConnection/audioProducer/setMuted', true);
    } catch (error) {
      console.error('unmuteMic() | failed: %o', error);
    }
  }

  async unmuteMic() {
    try {
      await this.client.unmuteMicrophone();
      store.dispatch('mediaSoupConnection/audioProducer/setMuted', false);
    } catch (error) {
      console.error('unmuteMic() | failed: %o', error);
    }
  }

  async getConsumerRemoteStats() {
    return this.client.getConsumerRemoteStats();
  }

  async getProducerRemoteStats() {
    return this.client.getProducerRemoteStats();
  }

  isRecvConnected() {
    return this.client.isRecvConnected();
  }

  isSendConnected() {
    return this.client.isSendConnected();
  }
}
