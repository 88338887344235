
























import { FrontendChannel } from '@/@types/types';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex';
import AppAudioButton from '../shared/AudioButton.vue';

const { mapState } = createNamespacedHelpers('mediaSoupConnection/audioConsumer');
const notifications = createNamespacedHelpers('notifications');

export default defineComponent({
  name: 'AppSelectedChannel',
  components: { AppAudioButton },
  props: {
    channel: Object as PropType<FrontendChannel>,
    mergedStream: MediaStream,
  },
  setup(props) {
    return {
      name: computed(() => {
        const externalName =
          props.channel?.source?.__typename === 'PortableSource' && props.channel?.source?.externalName;
        return props.channel?.cloudDelay ? externalName : props.channel?.name;
      }),
    };
  },
  computed: {
    ...mapState({
      playing: 'playing',
      value: 'value',
    }),
  },
});
