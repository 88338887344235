<template>
  <v-dialog v-visibility-change="visibilityChange" v-model="visible" max-width="450">
    <v-card>
      <v-card-title> App was in background </v-card-title>
      <v-card-text>
        <p>
          For your browser, we can not ensure that playing and/or recording audio in background works as intended.
          <br /><br />
          On <b>{{ operatingSystem }}</b> make sure that your browser tab stays open and your screen is on and unlocked
        </p>
        <p><b>- or -</b></p>
        <p>just use the following browser:</p>
        <app-browser-list :browsers-data="supportedBrowser" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click.stop="cancel" color="primary" text>Got it</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppBrowserList from '@/components/shared/BrowserList';
import {
  browserOS,
  browsersDataFor,
  isSupportedBrowserPlayingInBackground,
  supportedBrowserPlayingInBackground,
} from '@/helpers/browser-detection';

export default {
  name: 'AppInBackgroundInformation',
  components: { AppBrowserList },
  props: {
    active: Boolean,
  },
  data() {
    return {
      visible: false,
      value: null,
    };
  },
  computed: {
    operatingSystem() {
      return browserOS.name;
    },
    supportedBrowser() {
      return browsersDataFor(supportedBrowserPlayingInBackground);
    },
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    open() {
      this.visible = true;
    },
    visibilityChange(event, hidden) {
      if (!hidden && this.active && !isSupportedBrowserPlayingInBackground) {
        this.open();
      }
    },
  },
};
</script>

<style scoped></style>
