<template>
  <div class="container">
    <app-audio-input-wave v-if="active" :stream="stream" />
    <v-btn
      class="recording-button"
      :color="active ? 'primary' : 'white'"
      light
      v-bind="$attrs"
      v-on="$listeners"
      :aria-label="ariaLabel"
    >
      <slot />
    </v-btn>
  </div>
</template>

<script>
import AppAudioInputWave from './AudioInputWave';
export default {
  name: 'AppAudioButton',
  components: { AppAudioInputWave },
  props: {
    active: Boolean,
    stream: MediaStream,
    ariaLabel: String,
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.recording-button {
  width: 135px;
  height: 135px !important;
  border-radius: 75px;
}

::v-deep .v-btn.v-btn--disabled .v-icon {
  color: var(--v-primary-base) !important;
}
</style>
