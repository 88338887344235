import * as _ from 'lodash';
import moment from 'moment';
import { apolloClient } from '../../plugins/vue-apollo';
import SET_SYSTEM_DATE_TIME_MUTATION from './SystemTime.mutation.gql';
import GET_SYSTEM_DATE_TIME_QUERY from './SystemTime.query.gql';

const isServerTimeWrong = (serverTime) => {
  return (
    moment(serverTime).subtract(1, 'minutes').isAfter(moment()) ||
    moment(serverTime).add(1, 'minutes').isBefore(moment())
  );
};

export const checkSystemTime = async () => {
  const serverTime = await apolloClient
    .query({
      fetchPolicy: 'no-cache',
      query: GET_SYSTEM_DATE_TIME_QUERY,
    })
    .then((response) => _.get(response, 'data.systemInformation.time'));

  if (serverTime && isServerTimeWrong(serverTime)) {
    await apolloClient.mutate({
      variables: { data: { isoDateTime: new Date().toISOString() } },
      mutation: SET_SYSTEM_DATE_TIME_MUTATION,
    });
  }
};
