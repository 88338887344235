import { FrontendChannel } from '@/@types/types';
import store from '@/store';
import { Channel, MediaRoom } from '@linkbox/sdk';
import * as _ from 'lodash';

export function getMediaRoomUrl(channel: FrontendChannel) {
  const mediaRoom: MediaRoom = _.get(channel, 'source.mediaRoom');
  if (!mediaRoom) {
    return null;
  }
  if (window.location.protocol === 'https:') {
    const isCloudDeployment = store.getters['systemInformation/isCloudDeployment'];
    if (isCloudDeployment) {
      return channel.cloudDelay ? mediaRoom.externalUrl : mediaRoom.internalUrl;
    }
    return mediaRoom.externalUrl ?? mediaRoom.internalUrl;
  } else {
    return mediaRoom.internalUrl;
  }
}

export function filterHiddenChannels(channels: Channel[], isLoggedIn: boolean): Channel[] {
  return isLoggedIn ? _.filter(channels, { enabled: true }) : _.filter(channels, { enabled: true, listed: true });
}
