<template>
  <div>
    <div v-if="selectableChannels.length" class="caption mb-6">Select a channel</div>
    <div v-if="!selectableChannels.length" class="font-italic mt-6">
      <div class="mb-4">No channels are available</div>
    </div>
    <app-channel-list :channels="selectableChannels" @select="$emit('input', $event)" />
  </div>
</template>

<script>
import AppChannelList from '@/components/channels/ChannelList';
import * as _ from 'lodash';

export default {
  name: 'AppSpeakerChannelSelection',
  components: { AppChannelList },
  props: {
    channels: Array,
    isAdmin: Boolean,
  },
  computed: {
    selectableChannels() {
      return this.isAdmin
        ? _.filter(this.channels, { source: { __typename: 'PortableSource' } })
        : _.filter(this.channels, { source: { __typename: 'PortableSource' }, listed: true });
    },
  },
};
</script>

<style scoped lang="scss">
.hint {
  max-width: 350px;
  margin: 0 auto;
}
</style>
