import Bowser from 'bowser';
import * as _ from 'lodash';

const browser = Bowser.getParser(window.navigator.userAgent);

export const browserOS = browser.getOS();
export const browserPlatform = browser.getPlatformType();

export const BROWSER_DATA = [
  { id: 'chrome', name: 'Chrome', icon: 'mdi-google-chrome' },
  { id: 'firefox', name: 'Firefox', icon: 'mdi-firefox' },
  { id: 'safari', name: 'Safari', icon: 'mdi-apple-safari' },
  { id: 'edge', name: 'Edge', icon: 'mdi-edge' },
];

const BROWSER_FOR_RECORDING = ['chrome', 'firefox', 'edge'];
const BROWSER_FOR_RECORDING_IOS = ['safari'];
const BROWSER_FOR_RECORDING_MacOS = ['safari', 'chrome', 'firefox', 'edge'];

export const supportedBrowserForRecording = isIOS()
  ? BROWSER_FOR_RECORDING_IOS
  : isMacOS()
  ? BROWSER_FOR_RECORDING_MacOS
  : BROWSER_FOR_RECORDING;

export const isSupportedBrowserForRecording = browser.satisfies({
  ...toBowserSatisfyObject(supportedBrowserForRecording),
});

const BROWSER_FOR_PLAYING = ['chrome', 'firefox', 'edge'];
const BROWSER_FOR_PLAYING_IOS = ['safari', 'chrome'];
const BROWSER_FOR_PLAYING_MacOS = ['safari', 'chrome', 'firefox', 'edge'];

export const supportedBrowserForPlaying = isIOS()
  ? BROWSER_FOR_PLAYING_IOS
  : isMacOS()
  ? BROWSER_FOR_PLAYING_MacOS
  : BROWSER_FOR_PLAYING;

export const isSupportedBrowserForPlaying = browser.satisfies({
  ...toBowserSatisfyObject(supportedBrowserForPlaying),
});

const BROWSER_FOR_PLAYING_IN_BACKGROUND = ['firefox'];
const BROWSER_FOR_PLAYING_IN_BACKGROUND_IOS = ['safari'];

export const supportedBrowserPlayingInBackground = isIOS()
  ? BROWSER_FOR_PLAYING_IN_BACKGROUND_IOS
  : BROWSER_FOR_PLAYING_IN_BACKGROUND;

export const isSupportedBrowserPlayingInBackground =
  isDesktop() ||
  browser.satisfies({
    ...toBowserSatisfyObject(supportedBrowserPlayingInBackground),
  });

function isIOS() {
  return browserOS.name === 'iOS';
}

function isMacOS() {
  return browserOS.name === 'macOS';
}

export function isAndroid() {
  return browserOS.name === 'Android';
}

export function isMobile() {
  return browserPlatform === 'mobile';
}

export function isDesktop() {
  return browserPlatform === 'desktop';
}

export const isSupportedBrowserRecordAndDownload = isDesktop() || isAndroid();

function toBowserSatisfyObject(browserList) {
  const result = {};
  for (let i = 0; i < browserList.length; ++i) {
    result[browserList[i]] = '>0';
  }
  return result;
}

export function browsersDataFor(browserIds) {
  return _.filter(BROWSER_DATA, (browser) => _.includes(browserIds, browser.id));
}
