import ConnectionClient from '@/utils/connection';
import '@mdi/font/css/materialdesignicons.css';
import VueCompositionAPI from '@vue/composition-api';
import 'smartbanner.js/dist/smartbanner.min.css';
import 'smartbanner.js/dist/smartbanner.min.js';
import 'typeface-open-sans';
import Vue from 'vue';
import VueResource from 'vue-resource';
import App from './App.vue';
import { createProvider } from './plugins/vue-apollo';
import './plugins/vue-audio-visual';
import './plugins/vue-visibility-change';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Vue.prototype.$mediaSoup = new ConnectionClient();
Vue.prototype.$audioContext = null;

// Vue resource for http
Vue.use(VueResource);

new Vue({
  store,
  apolloProvider: createProvider(),
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
