import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#48B491',
        error: '#FF7466',
        info: '#5372B5',
        success: '#50C86B',
        warning: '#FF9A66',
        white: '#F5F5F5',
      },
    },
    options: {
      customProperties: true,
    },
  },
});
