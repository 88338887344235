<script>
const expandedParentClass = '';
export default {
  name: 'AppTransitionExpand',
  functional: true,
  props: {
    group: Boolean,
    horizontal: Boolean,
  },
  render(createElement, context) {
    function afterLeave(el) {
      if (expandedParentClass && el._parent) {
        el._parent.classList.remove(expandedParentClass);
      }
      resetStyles(el);
    }

    function resetStyles(el) {
      const size = el._initialStyle.height;
      const width = el._initialStyle.width;
      const margin = el._initialStyle.margin;
      el.style.overflow = el._initialStyle.overflow;
      if (size != null) el.style.height = size;
      if (width != null) el.style.width = width;
      if (margin != null) el.style.margin = margin;
      delete el._initialStyle;
    }

    const data = {
      props: {
        name: 'expand',
      },
      on: {
        beforeEnter(el) {
          el._parent = el.parentNode;
          el._initialStyle = {
            transition: el.style.transition,
            visibility: el.style.visibility,
            margin: getComputedStyle(el).margin,
            overflow: el.style.overflow,
            height: el.style.height,
            width: el.style.width,
          };
        },

        enter(el) {
          const initialStyle = el._initialStyle;
          const offset = `${el.offsetHeight}px`;
          const offsetWidth = `${el.offsetWidth}px`;

          el.style.setProperty('transition', 'none', 'important');
          el.style.visibility = 'hidden';
          el.style.visibility = initialStyle.visibility;
          el.style.overflow = 'hidden';

          el.style.setProperty('margin-top', '0px', 'important');
          el.style.setProperty('margin-bottom', '0px', 'important');
          el.style.height = '0';
          void el.offsetHeight; // force reflow

          if (context.props.horizontal) {
            el.style.setProperty('margin-left', '0px', 'important');
            el.style.setProperty('margin-right', '0px', 'important');
            el.style.width = '0';
            void el.offsetWidth; // force reflow
          }

          el.style.transition = initialStyle.transition;

          if (expandedParentClass && el._parent) {
            el._parent.classList.add(expandedParentClass);
          }

          requestAnimationFrame(() => {
            el.style.height = offset;
            el.style.margin = initialStyle.margin;
            if (context.props.horizontal) {
              el.style.width = offsetWidth;
            }
          });
        },

        afterEnter: resetStyles,
        enterCancelled: resetStyles,

        leave(el) {
          el._initialStyle = {
            transition: '',
            visibility: '',
            overflow: el.style.overflow,
            height: el.style.height,
            width: el.style.width,
          };
          el.style.overflow = 'hidden';
          el.style.height = `${el.offsetHeight}px`;
          void el.offsetHeight; // force reflow

          if (context.props.horizontal) {
            el.style.width = `${el.offsetWidth}px`;
            void el.offsetWidth; // force reflow
          }

          requestAnimationFrame(() => {
            el.style.height = '0';
            el.style.setProperty('margin', '0px', 'important');
            el.style.setProperty('padding', '0px', 'important');
            if (context.props.horizontal) {
              el.style.width = '0';
            }
          });
        },

        afterLeave,
        leaveCancelled: afterLeave,
      },
    };

    return createElement(`transition${context.props.group ? '-group' : ''}`, data, context.children);
  },
};
</script>

<style scoped>
* {
  will-change: height, width;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.4s ease-in-out, width 0.4s ease-in-out, padding 0.4s ease-in-out, opacity 0.4s ease-in-out,
    margin 0.4s ease-in-out !important;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  margin: 0;
  padding: 0;
}
</style>
