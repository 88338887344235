<template>
  <div class="text-center">
    <h1 class="mb-6">Intercom</h1>
    <div>Joining an intercom channel is only possible in the linkbox app.</div>
    <app-get-our-app />
  </div>
</template>

<script>
import AppGetOurApp from '@/components/shared/GetOurApp';
export default {
  name: 'IntercomView',
  components: { AppGetOurApp },
};
</script>

<style scoped lang="scss">
img {
  width: 160px;
}
</style>
