<template>
  <div>
    <app-in-background-information :active="playing" />
  </div>
</template>

<script>
import AppInBackgroundInformation from '@/components/audio/InBackgroundInformation';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('mediaSoupConnection/audioConsumer');

export default {
  name: 'AppConsumerAudio',
  components: { AppInBackgroundInformation },
  computed: {
    ...mapState({
      playing: (state) => state.playing,
    }),
  },
  methods: {
    ...mapActions({
      play: 'play',
      pause: 'pause',
    }),
  },
};
</script>

<style scoped></style>
