<template>
  <div>
    <div v-if="recommendation">We recommend to use our linkbox app. You can get it here:</div>
    <div v-else>Switch to our native linkbox app or get it here:</div>
    <a class="d-block mt-6" href="https://apps.apple.com/de/app/linkbox/id1536635594">
      <img src="../../assets/apple-store-badge.svg" alt="apple store" />
    </a>
    <a href="https://play.google.com/store/apps/details?id=de.linkbox.app">
      <img src="../../assets/google-play-badge.svg" alt="google play" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppGetOurApp',
  props: {
    recommendation: Boolean,
  },
};
</script>

<style scoped>
img {
  width: 160px;
}
</style>
