import * as _ from 'lodash';
import { apolloClient } from '../plugins/vue-apollo';
import CurrentUserQuery from './CurrentUsers.query.gql';

export default {
  namespaced: true,
  state: {
    currentUser: null,
  },
  getters: {
    isLoggedIn: (state) => state.currentUser !== null,
    isAdmin: (state) => _.get(state, 'currentUser.role') === 'Administrator',
    name: (state) => _.get(state, 'currentUser.username'),
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  actions: {
    loadCurrentUser({ commit }) {
      return apolloClient
        .query({
          fetchPolicy: 'no-cache',
          query: CurrentUserQuery,
        })
        .then((response) => {
          commit('setCurrentUser', _.get(response, 'data.currentUser'));
        })
        .catch(() => {
          commit('setCurrentUser', null);
        });
    },
  },
};
