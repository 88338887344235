<template>
  <v-app>
    <app-navigation />
    <v-main>
      <app-connection-score
        :has-consumer="hasConsumer"
        :producer="producer"
        :connected="connected"
        :connecting="connecting"
      />
      <app-notifications />
      <v-container class="fill-height my-4">
        <div class="fill-width text-center">
          <router-view :channels="channels" :configuration="configuration" :loading="!!loading" />
        </div>
      </v-container>
    </v-main>
    <app-bottom-navigation :channels="channels" />
    <app-consumer-audio />
  </v-app>
</template>

<script>
import AppConnectionScore from '@/components/audio/ConnectionScore';
import CHANNELS from '@/components/shared/gql/Channels.query.gql';
import CHANNELS_SUBSCRIPTION from '@/components/shared/gql/Channels.subscription.gql';
import CONFIGURATION from '@/components/shared/gql/Configuration.query.gql';
import SOURCES from '@/components/shared/gql/Sources.query.gql';
import SOURCES_SUBSCRIPTION from '@/components/shared/gql/Sources.subscription.gql';
import AppNotifications from '@/components/shared/Notifications';
import { updateQuery } from '@/helpers/graphql_helper';
import { createNamespacedHelpers, mapActions } from 'vuex';
import AppConsumerAudio from './components/audio/ConsumerAudio';
import AppBottomNavigation from './components/shared/AppBottomNavigation';
import AppNavigation from './components/shared/AppNavigation';
import { checkSystemTime } from './utils/systemTimeCheck';
const authStore = createNamespacedHelpers('auth');
const systemInformationStore = createNamespacedHelpers('systemInformation');
const licensesStore = createNamespacedHelpers('licenses');
const mediaSoupConnectionStore = createNamespacedHelpers('mediaSoupConnection');
const audioConsumerStore = createNamespacedHelpers('mediaSoupConnection/audioConsumer');
const audioProducerStore = createNamespacedHelpers('mediaSoupConnection/audioProducer');

export default {
  name: 'App',
  components: {
    AppConnectionScore,
    AppNotifications,
    AppConsumerAudio,
    AppBottomNavigation,
    AppNavigation,
  },
  apollo: {
    channels: {
      query: CHANNELS,
      subscribeToMore: {
        document: CHANNELS_SUBSCRIPTION,
        variables() {
          return {
            tenantId: this.tenantId,
          };
        },
        updateQuery: updateQuery('channel', 'channels'),
      },
    },
    sources: {
      query: SOURCES,
      subscribeToMore: {
        document: SOURCES_SUBSCRIPTION,
        variables() {
          return {
            tenantId: this.tenantId,
          };
        },
        updateQuery: updateQuery('source', 'sources'),
      },
    },
    configuration: {
      query: CONFIGURATION,
    },
  },
  data() {
    return {
      loading: 0,
      channels: [],
    };
  },
  methods: {
    ...authStore.mapActions({ loadCurrentUser: 'loadCurrentUser' }),
    ...systemInformationStore.mapActions({ loadSystemInformation: 'loadSystemInformation' }),
    ...licensesStore.mapActions({ loadLicenses: 'load' }),
    ...mapActions({ setAudioContext: 'setAudioContext' }),
  },
  computed: {
    ...systemInformationStore.mapState({ tenantId: 'tenantId' }),
    ...mediaSoupConnectionStore.mapState({
      connected: 'connected',
      connecting: 'connecting',
    }),
    ...audioConsumerStore.mapGetters({
      hasConsumer: 'hasConsumer',
    }),
    ...audioProducerStore.mapState({
      producer: 'producer',
    }),
  },
  created() {
    checkSystemTime();
    this.loadSystemInformation();
    this.loadLicenses();
  },
  mounted() {
    this.loadCurrentUser();
    const onFirstInteraction = () => {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      this.setAudioContext(new AudioContext());
      document.removeEventListener('click', onFirstInteraction);
    };
    document.addEventListener('click', onFirstInteraction);
  },
};
</script>

<style lang="scss">
.v-application:before {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  background: url('./assets/app-background.png');
  background-size: 226px 226px;
  background-repeat: repeat;
}
.v-btn--outlined {
  background: white;
}
.fill-width {
  width: 100%;
}
</style>
