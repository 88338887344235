export default {
  namespaced: true,
  state: {
    stream: null,
    producer: null,
    muted: false,
  },
  mutations: {
    setStream: (state, stream) => {
      state.stream = stream;
    },
    setProducer: (state, producer) => {
      state.producer = producer;
    },
    clearStream: (state) => {
      state.stream = null;
      state.producer = null;
      state.muted = false;
    },
    setMuted: (state, muted) => {
      state.muted = muted;
    },
  },
  actions: {
    setStream: ({ commit }, stream) => {
      commit('setStream', stream);
    },
    setProducer: ({ commit }, producer) => {
      commit('setProducer', producer);
    },
    setMuted: ({ commit }, muted) => {
      commit('setMuted', muted);
    },
    clear: ({ state, commit }) => {
      if (state.stream) {
        state.stream.getAudioTracks().forEach((track) => {
          track.stop();
        });
      }
      commit('clearStream');
    },
  },
};
