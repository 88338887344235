import { PluginObject } from 'vue/types/plugin';
import AvMedia from './avMedia';

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
    mozAudioContext: typeof AudioContext;
    msAudioContext: typeof AudioContext;
    mozRequestAnimationFrame: (callback: FrameRequestCallback) => number;
    msRequestAnimationFrame: (callback: FrameRequestCallback) => number;
  }
}

const AVPlugin: PluginObject<void> = {
  install: function (Vue: any) {
    window.AudioContext =
      window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      // @ts-ignore
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    // Components
    Vue.component(AvMedia.name, AvMedia);
  },
};

export default AVPlugin;
