import audioConsumer from './audioConsumer';
import audioProducer from './audioProducer';

export default {
  namespaced: true,
  modules: {
    audioConsumer,
    audioProducer,
  },
  state: {
    connected: false,
    connecting: false,
    peers: [],
  },
  mutations: {
    addPeer: (state, peer) => {
      state.peers = [...state.peers, peer];
    },
    setConnected: (state, connected) => {
      state.connected = connected;
    },
    setConnecting: (state, connecting) => {
      state.connecting = connecting;
    },
    selectChannel: (state, channel) => {
      state.selectedChannel = channel;
    },
  },
  actions: {
    addPeer: ({ commit }, peer) => {
      commit('addPeer', peer);
    },
    startConnecting: ({ commit }) => {
      commit('setConnected', false);
      commit('setConnecting', true);
    },
    setConnected: ({ commit }) => {
      commit('setConnected', true);
      commit('setConnecting', false);
    },
    setDisconnected: ({ commit }) => {
      commit('setConnected', false);
      commit('setConnecting', false);
    },
    selectChannel: ({ commit }, channel) => {
      commit('selectChannel', channel);
    },
  },
};
