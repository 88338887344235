<template>
  <div class="text-center">
    <h1 class="mb-6">Info & Help</h1>
    <div class="white-space__pre-wrap">{{ helpText }}</div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import CONFIG_HELP_TEXT from './ConfigurationHelpText.query.gql';

export default {
  name: 'AppInformation',
  apollo: {
    configuration: {
      query: CONFIG_HELP_TEXT,
    },
  },
  computed: {
    helpText() {
      return _.get(this.configuration, 'helpText', '');
    },
  },
};
</script>

<style scoped lang="scss">
.white-space__pre-wrap {
  white-space: pre-wrap;
}
</style>
