<template>
  <v-snackbar app top :color="color" :value="hasNotification" @input="hide">
    <v-row align="center" no-gutters>
      <v-col cols="auto">
        <v-icon large class="mr-4">mdi-alert</v-icon>
      </v-col>
      <v-col>
        <h3>{{ title }}</h3>
        <small class="d-block">{{ description }}</small>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import * as _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('notifications');

export default {
  name: 'AppNotifications',
  computed: {
    ...mapState({ notification: 'notification' }),
    hasNotification() {
      return !!this.notification;
    },
    title() {
      return _.get(this.notification, 'title');
    },
    description() {
      return _.get(this.notification, 'description');
    },
    color() {
      return _.get(this.notification, 'color');
    },
  },
  methods: {
    ...mapActions({ hide: 'hide' }),
  },
};
</script>

<style scoped></style>
