export default {
  namespaced: true,
  state: {
    notification: null,
  },
  mutations: {
    setNotification(state, notification) {
      state.notification = notification;
    },
  },
  actions: {
    showError({ commit }, { title, description }) {
      commit('setNotification', { title, description, color: 'error' });
    },
    hide({ commit }) {
      commit('setNotification', null);
    },
  },
};
