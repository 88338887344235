import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Channels',
    component: () => import('./views/Channels.vue'),
  },
  {
    path: '/speaker',
    name: 'Speaker',
    component: () => import('./views/Speaker.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/info',
    name: 'Information',
    component: () => import('./views/Information.vue'),
  },
  {
    path: '/intercom',
    name: 'Intercom',
    component: () => import('./views/Intercom.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// @ts-ignore
router.replace({ path: '/', redirect: '/' });

router.beforeEach(async (to, from, next) => {
  if (requiresAuth(to) && !(await isLoggedIn())) {
    window.location.href = '/admin/login?redirect=/speaker';
  } else {
    next();
  }
});

function requiresAuth(to) {
  return to.matched.some((record) => record.meta.requiresAuth);
}

async function isLoggedIn() {
  await store.dispatch('auth/loadCurrentUser');
  return store.getters['auth/isLoggedIn'];
}

export default router;
