
























import { ChannelType } from '@/components/shared/types';
import { filterHiddenChannels } from '@/helpers/channel-helper';
import { Channel } from '@linkbox/sdk';
import _ from 'lodash';
import Vue, { PropType } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const authStore = createNamespacedHelpers('auth');
const licensesStore = createNamespacedHelpers('licenses');

export default Vue.extend({
  name: 'AppBottomNavigation',
  props: {
    channels: Array as PropType<Channel[]>,
  },
  computed: {
    ...authStore.mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
    ...licensesStore.mapGetters({
      hasIntercomEnabled: 'hasIntercomEnabled',
    }),
    intercomVisible(): boolean {
      return this.visibleIntercomChannels?.length || (this.isLoggedIn && this.hasIntercomEnabled);
    },
    visibleIntercomChannels(): Channel[] {
      const filteredChannels = filterHiddenChannels(this.channels, this.isLoggedIn);
      return _.filter(filteredChannels, (channel: Channel) => {
        return channel.__typename === ChannelType.INTERCOM_CHANNEL;
      });
    },
  },
});
