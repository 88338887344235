<template>
  <app-information />
</template>

<script>
import AppInformation from '../components/information/index';
export default {
  name: 'InformationView',
  components: { AppInformation },
};
</script>
