import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import licenses from './licenses';
import mediaSoupConnection from './mediaSoupConnection/index';
import notifications from './notifications';
import systemInformation from './systemInformation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    systemInformation,
    mediaSoupConnection,
    notifications,
    licenses,
  },
  state: {
    selectedChannel: null,
    context: null,
  },
  mutations: {
    selectChannel: (state, channel) => {
      state.selectedChannel = channel;
    },
    setAudioContext: (state, audioContext) => {
      state.context = audioContext;
    },
  },
  actions: {
    setAudioContext: ({ commit }, audioContext) => {
      commit('setAudioContext', audioContext);
    },
    selectChannel: ({ commit }, channel) => {
      commit('selectChannel', channel);
    },
  },
});
