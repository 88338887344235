import _ from 'lodash';

const upsert = (array, test, obj) => {
  const result = _.find(array, test);
  if (result) {
    _.extend(result, obj);
  } else {
    array.push(obj);
  }
  return array;
};

export const updateQuery =
  (dataAttr, cacheAttr) =>
  (prev, { subscriptionData: { data } }) => {
    const entity = _.get(data, dataAttr, {});
    switch (entity.mutation) {
      case 'CREATED': {
        const allEntities = [entity.node, ...prev[cacheAttr]];
        return Object.assign({}, prev, { [cacheAttr]: allEntities });
      }
      case 'DELETED': {
        const allEntities = _.filter(prev[cacheAttr], (o) => o.id !== entity.node.id);
        return Object.assign({}, prev, { [cacheAttr]: allEntities });
      }
      case 'UPDATED': {
        return upsert(
          Object.assign({}, prev[cacheAttr]),
          (item) => {
            return item.id === data[dataAttr].node.id;
          },
          entity.node
        );
      }
      default:
        return prev;
    }
  };
