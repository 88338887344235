<template>
  <div>
    <app-device-not-supported-for-rtc v-if="!isDeviceSupported" />
    <app-speaker v-else-if="isHttps" />
    <div v-else>
      <h1 class="mb-6">Speak</h1>
      <div>Speaking is in your local network not possible with your browser app.</div>
      <app-get-our-app />
    </div>
  </div>
</template>

<script>
import AppDeviceNotSupportedForRtc from '@/components/shared/DeviceNotSupportedForRtc';
import AppGetOurApp from '@/components/shared/GetOurApp';
import { isDeviceSupported } from '@linkbox/rtc-sdk';
import AppSpeaker from '../components/speaker/index';

export default {
  name: 'SpeakerView',
  components: { AppDeviceNotSupportedForRtc, AppGetOurApp, AppSpeaker },
  computed: {
    isHttps() {
      return document.location.protocol === 'https:' || document.location.hostname === 'localhost';
    },
    isDeviceSupported() {
      return isDeviceSupported();
    },
  },
};
</script>
