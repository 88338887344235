import * as _ from 'lodash';
import { Consumer } from 'mediasoup-client/lib/Consumer';

export default {
  namespaced: true,
  state: {
    consumers: [] as Consumer[],
    playing: false,
    mergedStream: null,
  },
  getters: {
    hasConsumer: (state) => {
      return !_.isEmpty(state.consumers);
    },
  },
  mutations: {
    addConsumer: (state, consumer: Consumer) => {
      state.consumers = [...state.consumers, consumer];

      addAudioElement(consumer.id, consumer.track);
      if (state.playing) {
        playAudioElement(consumer.id);
      }
    },
    removeConsumer: (state, consumerId) => {
      state.consumers = state.consumers.filter((consumer: Consumer) => consumer.id !== consumerId);
      pauseAudioElement(consumerId, true);
    },
    clearConsumers: (state) => {
      for (const consumer of state.consumers) {
        removeElement(consumer.id);
      }
      state.consumers = [];
    },
    setPlaying: (state, playing) => {
      state.playing = playing;
      if (playing) {
        for (const consumer of state.consumers) {
          playAudioElement(consumer.id);
        }
      } else {
        for (const consumer of state.consumers) {
          pauseAudioElement(consumer.id, false);
        }
      }
    },
    setMergedStream: (state, stream) => {
      state.mergedStream = stream;
    },
  },
  actions: {
    add: async ({ state, commit, dispatch }, consumer) => {
      commit('addConsumer', consumer);
      dispatch('updateMergedNode');
    },
    remove: ({ commit, dispatch }, consumerId) => {
      commit('removeConsumer', consumerId);
      dispatch('updateMergedNode');
    },
    updateMergedNode: ({ state, rootState, commit }) => {
      const destination = rootState.context.createMediaStreamDestination();

      if (state.consumers.length) {
        const merger = rootState.context.createChannelMerger(state.consumers.length);

        for (const consumer of state.consumers) {
          const stream = new MediaStream();
          stream.addTrack(consumer.track);
          const source = rootState.context.createMediaStreamSource(stream);
          source.connect(merger);
        }

        merger.connect(destination);
      }

      commit('setMergedStream', destination.stream);
    },
    clear: ({ commit, dispatch }) => {
      commit('clearConsumers');
      dispatch('pause');
    },
    togglePlay({ state, dispatch }) {
      if (state.playing) {
        dispatch('pause');
      } else {
        dispatch('play');
      }
    },
    play({ commit }) {
      commit('setPlaying', true);
    },
    pause({ commit }) {
      commit('setPlaying', false);
    },
  },
};

function addAudioElement(id: string, track: MediaStreamTrack) {
  const audio = document.createElement('audio');
  audio.setAttribute('id', id);
  audio.id = id;

  const stream = new MediaStream();
  stream.addTrack(track);
  audio.srcObject = stream;

  document.body.appendChild(audio);
}

function removeElement(id: string) {
  const elem = document.getElementById(id);
  return elem?.parentNode?.removeChild(elem);
}

function playAudioElement(id: string) {
  const audio = document.getElementById(id) as HTMLAudioElement | null;
  audio?.play();
}

function pauseAudioElement(id: string, remove: boolean) {
  const audio = document.getElementById(id) as HTMLAudioElement | null;
  audio?.pause();
  if (remove) {
    audio?.remove();
    removeElement(id);
  }
}
