<template>
  <div class="text-center">
    <div class="caption mb-6">for {{ selectedChannelName }}</div>

    <app-audio-controls
      :stream="stream"
      :show-side-buttons="connected"
      :muted="muted"
      start-icon="mdi-microphone"
      @start="connected ? stopRecording() : startRecording()"
      @cancel="stopRecording"
      @toggleMute="toggleMute"
    />

    <app-in-background-information :active="connected" />

    <v-switch
      v-if="isDownloadSupported"
      v-model="download"
      :disabled="connected"
      label="Record & download"
      class="d-inline-block mt-4"
    />

    <app-confirm-dialog
      ref="downloadConfirmDialog"
      title="File download"
      confirm-text="Download"
      confirm-color="primary"
      @confirm="downloadRecorderContent"
      @cancel="cancel"
    >
      Do you want to download the recorded file?
    </app-confirm-dialog>
  </div>
</template>

<script>
import AppInBackgroundInformation from '@/components/audio/InBackgroundInformation';
import AppConfirmDialog from '@/components/shared/ConfirmDialog';
import { isSupportedBrowserRecordAndDownload } from '@/helpers/browser-detection';
import { getMediaRoomUrl } from '@/helpers/channel-helper';
import { downloadRecordFile, startRecord, stopRecord } from '@/helpers/recorder';
import * as _ from 'lodash';
import AppAudioControls from '../shared/AudioControls';

export default {
  name: 'AppSpeakerRecord',
  components: { AppConfirmDialog, AppInBackgroundInformation, AppAudioControls },
  props: {
    channel: Object,
    connected: Boolean,
    stream: MediaStream,
    muted: Boolean,
  },
  data() {
    return {
      inAnimation: false,
      download: false,
    };
  },
  computed: {
    selectedChannelName() {
      return _.get(this.channel, 'name');
    },
    mediaRoomUrl() {
      return getMediaRoomUrl(this.channel);
    },
    isDownloadSupported() {
      return isSupportedBrowserRecordAndDownload;
    },
  },
  methods: {
    async startRecording() {
      this.$mediaSoup.connectAndProduce(this.mediaRoomUrl, this.selectedDeviceId);
      if (this.download) {
        startRecord();
      }
    },
    stopRecording() {
      this.$mediaSoup.disconnect();
      if (this.download) {
        stopRecord();
        this.$refs.downloadConfirmDialog.open();
      } else {
        this.cancel();
      }
    },
    downloadRecorderContent() {
      downloadRecordFile();
      this.cancel();
    },
    cancel() {
      this.$emit('cancel');
    },
    toggleMute() {
      if (this.muted) {
        this.$mediaSoup.unmuteMic();
      } else {
        this.$mediaSoup.muteMic();
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
