<template>
  <div>
    <div class="mb-2">
      For this operating system your current browser is not supported. For <b>{{ operatingSystem }}</b> please choose
      one of the following:
    </div>
    <div>
      <app-browser-list :browsers-data="supportedBrowser" />
    </div>
  </div>
</template>

<script>
import AppBrowserList from '@/components/shared/BrowserList';
import { browserOS, browsersDataFor, supportedBrowserForRecording } from '@/helpers/browser-detection';

export default {
  name: 'AppSpeakerBrowserNotSupportedMessage',
  components: { AppBrowserList },
  computed: {
    operatingSystem() {
      return browserOS.name;
    },
    supportedBrowser() {
      return browsersDataFor(supportedBrowserForRecording);
    },
  },
};
</script>

<style scoped></style>
