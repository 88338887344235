import { apolloClient } from '@/plugins/vue-apollo';
import { Quota } from '@linkbox/sdk';
import * as _ from 'lodash';
import { Module } from 'vuex';
import QuotaQuery from './Quota.query.gql';

class LicensesStore {
  quota: Quota | null = null;
}

export default <Module<LicensesStore, any>>{
  namespaced: true,
  state: new LicensesStore(),
  getters: {
    hasIntercomEnabled(state) {
      return !!state?.quota?.intercom;
    },
  },
  mutations: {
    setQuota(state, quota) {
      state.quota = quota;
    },
  },
  actions: {
    load({ commit }) {
      apolloClient
        .query({
          query: QuotaQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data }: { data: { quota: Quota } }) => {
          commit('setQuota', _.get(data, 'quota'));
        });
    },
  },
};
