import MicRecorder from 'mic-recorder';

const recorder = new MicRecorder({
  bitRate: 128,
});

export function startRecord() {
  recorder && recorder.start();
}

export function stopRecord() {
  recorder && recorder.stop();
}

export function downloadRecordFile() {
  recorder.getAudio().then(([buffer, blob]) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      downloadFile(reader.result);
    });

    const file = new File(buffer, 'music.mp3', {
      type: blob.type,
      lastModified: Date.now(),
    });
    reader.readAsDataURL(file);
  });
}

function downloadFile(url) {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'record.mp3';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('oh no!'));
}
