<template>
  <div>
    <span v-for="browser in browsersData" :key="browser.id">
      <v-chip outlined class="ma-1">
        <v-icon class="ml-n2 mr-1">{{ browser.icon }}</v-icon>
        {{ browser.name }}
      </v-chip>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppBrowserList',
  props: {
    browsersData: Array,
  },
};
</script>

<style scoped></style>
