<template>
  <div class="text-center">
    <h1 class="mb-n1">Speak</h1>
    <app-speaker-browser-not-supported-message v-if="!isSupportedBrowserForRecording()" class="mt-4" />
    <div v-else>
      <app-transition-expand>
        <app-speaker-channel-selection
          v-if="!selectedChannel"
          :channels="listenOnlyChannels"
          :is-admin="isAdmin"
          @input="selectedChannel = $event"
        />
      </app-transition-expand>
      <app-transition-expand>
        <app-speaker-record
          v-if="selectedChannel"
          ref="speakerRecord"
          :channel="selectedChannel"
          :connected="connected"
          :stream="producerAudioStream"
          :muted="producerMuted"
          @cancel="selectedChannel = null"
        />
      </app-transition-expand>
    </div>
  </div>
</template>

<script>
import { ChannelType } from '@/components/shared/types';
import * as _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { isSupportedBrowserForRecording } from '../../helpers/browser-detection';
import { updateQuery } from '../../helpers/graphql_helper';
import CHANNELS from '../shared/gql/Channels.query.gql';
import CHANNELS_SUBSCRIPTION from '../shared/gql/Channels.subscription.gql';
import AppTransitionExpand from '../shared/TransitionExpand';
import AppSpeakerBrowserNotSupportedMessage from './SpeakerBrowserNotSupportedMessage';
import AppSpeakerChannelSelection from './SpeakerChannelSelection';
import AppSpeakerRecord from './SpeakerRecord';
const authStore = createNamespacedHelpers('auth');
const mediaSoupConnectionStore = createNamespacedHelpers('mediaSoupConnection');
const systemInformationStore = createNamespacedHelpers('systemInformation');

export default {
  name: 'AppSpeaker',
  components: {
    AppSpeakerBrowserNotSupportedMessage,
    AppSpeakerRecord,
    AppSpeakerChannelSelection,
    AppTransitionExpand,
  },
  data() {
    return {
      selectedChannel: null,
      selectedDeviceId: null,
    };
  },
  apollo: {
    channels: {
      query: CHANNELS,
      subscribeToMore: {
        document: CHANNELS_SUBSCRIPTION,
        variables() {
          return {
            tenantId: this.tenantId,
          };
        },
        updateQuery: updateQuery('channel', 'channels'),
      },
    },
  },
  async mounted() {
    this.$mediaSoup.disconnect();
  },
  beforeDestroy() {
    if (this.$refs.speakerRecord) {
      this.$refs.speakerRecord.stopRecording();
    }
    this.$mediaSoup.disconnect();
  },
  methods: {
    isSupportedBrowserForRecording() {
      return isSupportedBrowserForRecording;
    },
  },
  computed: {
    ...mediaSoupConnectionStore.mapState({
      connected: (state) => state.connected,
      producerAudioStream: (state) => state.audioProducer.stream,
      producerMuted: (state) => state.audioProducer.muted,
    }),
    ...systemInformationStore.mapState({ tenantId: 'tenantId' }),
    ...authStore.mapGetters({
      isAdmin: 'isAdmin',
    }),
    listenOnlyChannels() {
      return _.filter(this.channels, { enabled: true, __typename: ChannelType.LISTEN_ONLY_CHANNEL });
    },
  },
};
</script>

<style scoped lang="scss"></style>
