import * as _ from 'lodash';
import { apolloClient } from '../../plugins/vue-apollo';
import SYSTEM_INFORMATION_QUERY from './SystemInformation.query.gql';

export default {
  namespaced: true,
  state: {
    tenantId: null,
    deploymentType: null,
  },
  getters: {
    isCloudDeployment: (state) => state.deploymentType === 'Cloud',
  },
  mutations: {
    setTenantId(state, tenantId) {
      state.tenantId = tenantId;
    },
    setDeploymentType(state, deploymentType) {
      state.deploymentType = deploymentType;
    },
  },
  actions: {
    async loadSystemInformation({ commit }) {
      const { tenantId, deploymentType } = await apolloClient
        .query({
          fetchPolicy: 'no-cache',
          query: SYSTEM_INFORMATION_QUERY,
        })
        .then((result) => _.get(result, 'data.systemInformation'));
      commit('setTenantId', tenantId);
      commit('setDeploymentType', deploymentType);
    },
  },
};
