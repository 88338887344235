<template>
  <v-app-bar app color="white" elevate-on-scroll light>
    <router-link to="/" class="logo">
      <img alt="Logo" class="fill-height" src="../../assets/logo.svg" />
    </router-link>
    <v-spacer />
    <v-menu v-if="isLoggedIn && currentUserName" offset-y bottom left light>
      <template v-slot:activator="{ on }">
        <v-chip v-on="on" outlined class="ml-6 mr-3" aria-label="Current user">
          <v-icon left>mdi-account</v-icon>
          <span class="text--ellipsis">{{ currentUserName }}</span>
        </v-chip>
      </template>
      <v-list dense>
        <v-list-item @click="logout">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn icon @click="redirectToAdminApp" aria-label="Jump to admin app">
      <v-icon>mdi-cog</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { onLogout } from '@/plugins/vue-apollo';
import { createNamespacedHelpers } from 'vuex';
const authStore = createNamespacedHelpers('auth');

export default {
  name: 'AppNavigation',
  computed: {
    ...authStore.mapGetters({
      isLoggedIn: 'isLoggedIn',
      currentUserName: 'name',
    }),
  },
  methods: {
    logout() {
      onLogout(this.$apolloProvider.clients.defaultClient);
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
    redirectToAdminApp() {
      window.location = process.env.VUE_APP_ADMIN_URL || '/admin';
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  margin: 12px 0;
  height: calc(100% - 24px);
}

.text--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-list-item__icon {
  margin-right: 10px !important;
}
</style>
