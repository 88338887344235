<template>
  <v-dialog v-model="visible" max-width="450" transition="slide-y-reverse-transition">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn text @click="onCancel">Cancel</v-btn>
        <v-btn text @click="onConfirm" :color="confirmColor">{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppConfirmDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    confirmColor: {
      type: String,
      default: 'error',
    },
  },
  data() {
    return {
      visible: false,
      data: {},
    };
  },
  methods: {
    open(data) {
      this.visible = true;
      this.data = data;
    },
    close() {
      this.visible = false;
    },
    onConfirm() {
      this.close();
      this.$emit('confirm', this.data);
    },
    onCancel() {
      this.close();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
