<template>
  <av-media
    ref="audioEffect"
    class="audio-input-wave"
    :media="stream"
    type="circle"
    lineColor="white"
    canv-fill-color="rgba(100, 100, 100, 0.3)"
    :canv-width="250"
    :canv-height="250"
    :circle-min="130"
    :frequ-line-cap="true"
    :frequ-lnum="5"
    :line-width="3"
  />
</template>

<script>
export default {
  name: 'AppAudioInputWave',
  props: {
    stream: MediaStream,
  },
  watch: {
    stream: {
      handler() {
        setTimeout(() => {
          if (this.$refs.audioEffect) {
            this.$refs.audioEffect.rerender();
          }
        }, 0.5);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.audio-input-wave {
  transition: transform ease-in-out 400ms 400ms;
  height: 250px;
  width: 250px;
  position: absolute;
  z-index: 0;
  &.zero-height {
    transform: scale(0);
  }
}
</style>
