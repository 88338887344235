








import Vue from 'vue';
import AppChannel from './Channel.vue';

export default Vue.extend({
  name: 'AppChannelList',
  components: { AppChannel },
  props: {
    channels: Array,
  },
});
