<template>
  <div>
    Your browser or device is not supported
    <app-get-our-app recommendation />
  </div>
</template>

<script>
import AppGetOurApp from '@/components/shared/GetOurApp';

export default {
  name: 'AppDeviceNotSupportedForRtc',
  components: {
    AppGetOurApp,
  },
};
</script>

<style scoped></style>
