<template>
  <v-system-bar v-if="(hasConsumer || hasProducer) && score !== null" :color="color" window absolute>
    <v-icon class="font-weight-bold">mdi-access-point</v-icon>
    <span>{{ text }}</span>
  </v-system-bar>
</template>

<script>
import * as _ from 'lodash';

export default {
  name: 'AppConnectionScore',
  props: {
    connected: Boolean,
    connecting: Boolean,
    hasConsumer: Boolean,
    producer: Object,
  },
  data() {
    return {
      interval: null,
      score: null,
    };
  },
  computed: {
    hasProducer() {
      return !_.isEmpty(this.producer);
    },
    color() {
      if (this.score >= 9) {
        return 'success';
      } else if (this.score >= 7) {
        return 'warning';
      } else {
        return 'error';
      }
    },
    text() {
      if (this.score === 10) {
        return 'Perfect connection';
      } else if (this.score === 9) {
        return 'Good connection';
      } else if (this.score >= 7) {
        return 'Connection could be better';
      } else if (this.score > 0) {
        return 'Bad connection';
      } else if (this.score <= 0) {
        return 'Not connected';
      } else {
        return '';
      }
    },
  },
  methods: {
    async consumerRemoteStats() {
      const consumerRemoteStats = await this.$mediaSoup.getConsumerRemoteStats();
      if (consumerRemoteStats && _.isArray(consumerRemoteStats)) {
        return _.first(_.filter(consumerRemoteStats, { type: 'outbound-rtp' }));
      } else {
        return null;
      }
    },
    async producerRemoteStats() {
      const producerRemoteStats = await this.$mediaSoup.getProducerRemoteStats();
      if (producerRemoteStats && _.isArray(producerRemoteStats)) {
        return _.first(_.filter(producerRemoteStats, { type: 'inbound-rtp' }));
      } else {
        return null;
      }
    },
    async updateStats() {
      if (this.hasConsumer) {
        const isConnected = this.$mediaSoup.isRecvConnected();
        if (!isConnected) {
          this.score = -1;
        } else {
          const outboundRemoteStats = await this.consumerRemoteStats();
          if (outboundRemoteStats) {
            this.score = outboundRemoteStats.score;
          }
        }
      } else if (this.producer) {
        const isConnected = this.$mediaSoup.isSendConnected();
        if (!isConnected) {
          this.score = -1;
        } else {
          const outboundRemoteStats = await this.producerRemoteStats();
          if (outboundRemoteStats) {
            this.score = outboundRemoteStats.score;
          }
        }
      }
    },
  },
  watch: {
    connected(connected) {
      if (connected) {
        clearInterval(this.interval);
        setTimeout(this.updateStats, 1000);
        this.interval = setInterval(this.updateStats, 5000);
      } else if (this.interval) {
        clearInterval(this.interval);
        this.score = null;
        this.interval = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.theme--dark.v-system-bar,
.theme--dark.v-system-bar .v-icon {
  color: white;
}
.v-system-bar {
  padding: 0 12px;
}
</style>
