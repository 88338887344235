









import { FrontendChannel } from '@/@types/types';
import { defineComponent, PropType } from '@vue/composition-api';
import * as _ from 'lodash';

export default defineComponent({
  name: 'AppChannel',
  props: {
    channel: Object as PropType<FrontendChannel>,
  },
  computed: {
    source() {
      return _.get(this.channel, 'source');
    },
    isListed() {
      return _.get(this.channel, 'listed');
    },
    name() {
      const externalName = this.channel?.source?.__typename === 'PortableSource' && this.channel?.source?.externalName;
      return this.cloudDelay ? externalName : this.channel?.name;
    },
    description() {
      return _.get(this.channel, 'description');
    },
    cloudDelay() {
      return _.get(this.channel, 'cloudDelay');
    },
  },
});
